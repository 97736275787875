<template>
<div class="subaccount">
    <div class="top">
        <span>子账号管理</span>
        <el-button icon="el-icon-plus" @click="clickAdd">添加子账号</el-button>
    </div>
    <div class="tableContainer">
        <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
            prop="accountNumber"
            label="账号">
        </el-table-column>
        <el-table-column
            prop="handleName"
            label="名称">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="添加日期">
        </el-table-column>
        <el-table-column
            label="操作" align="center"
            width="180">
            <template slot-scope="scope">
                <el-button type="text" @click="clickEdit(scope.row)">编辑</el-button>
                <el-button type="text" style="color:#f56c6c" @click="delAcc(scope.row)">删除</el-button>
            </template>
        </el-table-column>
        </el-table>
    </div>

    <el-dialog
    title="新增子账号"
    :visible.sync="dialogVisible1"
    width="fit-content"
    :before-close="handleClose1">
        <div class="newAccount">
            <el-form ref="form1" label-position="left" :model="form1" :rules="rules" label-width="90px">
                <el-form-item label="手机号" prop="telephone" required>
                    <div class="cus">
                        <el-input v-model="form1.telephone" placeholder="请输入手机号"></el-input>
                        <!-- <div class="explain">请填写您常用的手机号码</div> -->
                    </div>
                </el-form-item>
                <!-- <el-form-item label="验证">
                    <div class="cus">
                        <sliderValid @slideOver="slideOver"></sliderValid>
                        <div class="explain">拖动滑块到最右侧</div>
                    </div>
                </el-form-item> -->
                <el-form-item label="短信验证" prop="smsCode">
                    <div class="cus">
                        <el-input v-model="form1.smsCode">
                            <el-button slot="append" class="sendBtn" :style="slideValidResult?styleObj:{}" @click="sendCode">
                                {{countDownNum>0?countDownNum+'s后重发':'发送验证码'}}
                            </el-button>
                        </el-input>
                        <!-- <div class="explain">填写你获取到的验证码</div> -->
                    </div>
                </el-form-item>
                <el-form-item label="设置密码" prop="password" required>
                    <div class="cus">
                        <el-input v-model="form1.password" show-password></el-input>
                        <!-- <div class="explain">密码长度必须大于6位</div> -->
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <div class="cus">
                        <el-button type="" @click="cancelNew">取 消</el-button>
                        <el-button type="primary" @click="clickRegister">注 册</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
    </el-dialog>

    <el-dialog
    title="编辑子账号"
    :visible.sync="dialogVisible2"
    width="fit-content"
    :before-close="handleClose2">
        <div class="editAccount">
            <el-form ref="form2" label-position="left" :model="form2" :rules="rules2" label-width="90px">
                <el-form-item label="手机号" prop="telephone" required>
                    <div class="cus">
                        {{form2.telephone}}
                        <!-- <el-input v-model="form2.telephone"></el-input> -->
                        <!-- <div class="explain">请填写您常用的手机号码</div> -->
                    </div>
                </el-form-item>
                <el-form-item label="名称" prop="nickName" required>
                    <div class="cus">
                        <el-input v-model="form2.nickName"></el-input>
                        <!-- <div class="explain">请填写您常用的手机号码</div> -->
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <div class="cus">
                        <el-button type="" @click="cancelEdit">取 消</el-button>
                        <el-button type="primary" @click="clickSubmitEdit">确 定</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
    </el-dialog>
</div>
</template>

<script>
import {getSubUserList,registerUser,sendValidCode,editUserInfo,delSubUser} from "@/axios/api"
import sliderValid from "@/components/sliderValid2"
export default {
    components:{
        sliderValid
    },
    data(){
        var validateTel=(rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入手机号'));
            }else if(!this.$commonFun.regTelephone(value)){
                callback(new Error('请正确输入手机号'));
            }else{
                callback();
            }
        }
        var validatePwd = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                // if (this.form.confirmPassword !== '') {
                //     this.$refs.form.validateField('confirmPassword');
                // }
                callback();
            }
        };
        // var validatePwd2=(rule,value,callback)=>{
        //     if (value === '') {
        //         callback(new Error('请再次输入密码'));
        //     } else if (value !== this.form.password) {
        //         callback(new Error('两次输入密码不一致!'));
        //     } else {
        //         callback();
        //     }
        // }
        return{
            tableData:[{}],
            dialogVisible1:false,
            form1:{
                telephone:"",
                password:"",
                smsCode:"",
            },
            rules:{
                telephone:[
                    {validator:validateTel,trigger:"blur"}
                ],
                smsCode:[
                    {required: true, message: '请输入验证码',trigger:"blur"}
                ],
                password:[
                    {validator:validatePwd,trigger:"blur"}
                ],
                // confirmPassword:[
                //     {validator:validatePwd2,trigger:"blur"}
                // ],
            },
            slideValidResult:false,//滑动验证
            styleObj:{
                background:"#00b894",
                color:"#fff",
                height:"51px"
            },
            countDownNum:0,

            dialogVisible2:false,
            form2:{
                telephone:"",
                nickName:"",
            },
            rules2:{
                nickName:[
                    {required: true, message: '请输入名称',trigger:"blur"}
                ]
            }
        }
    },
    methods: {
        handleClose1(done){
            if(this.form1.telephone==''&&this.form1.smsCode==""&&this.form1.password==""){
                done()
            }else{
                this.$confirm("表单有未提交内容，确认关闭？",'提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    done();
                }).catch(()=>{})
            }
        },
        handleClose2(){},
        gainSubaccountList(){
            getSubUserList().then(res=>{
                console.log("子账号列表",res);
                if(res.code==200){
                    res.data.forEach(item=>{
                        item.accountNumber=item.telephone.substring(0,3)+"****"+item.telephone.substring(7);
                        item.handleName=item.nickName||item.accountNumber;
                    })
                }
            })
        },
        // 删除账号
        delAcc(row){
            this.$confirm("确认删除该账号？",'提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                // console.log(row)
                delSubUser({subUserId:row.userId}).then(res=>{
                    console.log("删除子账号结果",res);
                    if(res.code==200){
                        this.gainSubaccountList()
                        this.$commonFun.wrongTip(this,res.msg||res.message,'success')
                    }else{
                        this.$commonFun.wrongTip(this,res.msg||res.message)
                    }
                })
            }).catch(()=>{})
        },
        // 点击编辑
        clickEdit(row){
            this.form2=row;
            this.dialogVisible2=true
        },
        cancelEdit(){
            this.dialogVisible2=false
        },
        clickSubmitEdit(){
            this.$refs.form2.validate(valid=>{
                if(valid){
                    editUserInfo({
                        userId:this.form2.userId,
                        telephone:this.form2.telephone,
                        nickName:this.form2.nickName,
                    }).then(res=>{
                         if(res.code==200){
                            this.$commonFun.wrongTip(this,res.msg||res.message,'success')
                            this.dialogVisible2=false;
                        }else{
                            this.$commonFun.wrongTip(this,res.msg||res.message)
                        }
                    })
                }
            })
        },
        // 点击新增账号
        clickAdd(){
            this.form1={
                telephone:"",
                password:"",
                smsCode:"",
            }
            this.dialogVisible1=true;
        },
        // 取消新增
        cancelNew(){
            this.dialogVisible1=false;
        },
        slideOver(val){
            console.log("到头")
            this.slideValidResult=true
        },
        // 发送验证码
        sendCode(){
            // if(!this.slideValidResult){
            //     return
            // }
            // if(!this.form1.telephone){
            //     return
            // }
            if(this.countDownNum>0){
                this.$message(this.countDownNum+"s后可重发")
                return
            }
            var t=new Date().getTime()
            sendValidCode({
                telephone:this.form1.telephone,
                // sign:MD5.createMD5String(this.form.telephone+'YDJJ#@!'+t)
            }).then(res=>{
                console.log("发送验证码",res);
                // if(res.code=200){
                    this.setCountDown()
                // }
            })
        },
        setCountDown(){
            this.countDownNum=60
            var timer=setInterval(()=>{
                this.countDownNum--;
                if(this.countDownNum<=0){
                    clearInterval(timer);
                }
            },1000)
        },
        // 注册
        clickRegister(){
            // if(!this.slideValidResult){
            //     return
            // }
            this.$refs.form1.validate(valid=>{
                if(valid){
                    registerUser({
                        telephone:this.form1.telephone,
                        password:this.form1.password,
                        smsCode:this.form1.smsCode
                    }).then(res=>{
                        console.log("注册结果",res);
                        if(res.code==200){
                            this.$commonFun.wrongTip(this,res.msg||res.message,'success')
                            // setTimeout(()=>{
                            //     this.$router.replace('/login')
                            // },2000)
                            this.dialogVisible1=false;
                        }else{
                            // this.$message(res.msg||res.message)
                            this.$commonFun.wrongTip(this,res.msg||res.message)
                        }
                    })
                }
            })
        },
    },
    created() {
        this.gainSubaccountList();
    },
}
</script>

<style lang="less" scoped>
.subaccount{
    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &>span{
            font-size: 20px;
            color: #333;
        }
    }
    .tableContainer{
        margin-top: 20px;
        /deep/.el-table{
            .el-table__header th{
                background: #e9e9e9;
            }
        }
    }

    .newAccount{
        width: 500px;
        padding: 0 20px;
        /deep/.el-form{
            .el-form-item{
                margin-bottom: 50px;
            }
        }
    }
    .editAccount{
        width: 500px;
        padding: 0 20px;
    }
}
</style>